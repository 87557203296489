import JSZip from 'jszip'
import { useEffect, useRef, useState } from 'react'
import type { UnityConfig } from 'react-unity-webgl'

// import { GameSDKMonitor } from '../../functional'
import { createObjectURLFromArchive } from './utils'
import { Canvas } from './Canvas'
import { GameBuild } from './types'
import { GameSDKMonitor } from './GameSDKMonitor'
import { CapncoLoader } from './Loader/CaptainAndCompany'

interface Props {
  gameBuild: GameBuild
  jwt: string
}

export const UnityGame = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { gameBuild } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [files, setFiles] = useState<Record<string, string>>({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [loadingProgression, setLoadingProgression] = useState(0)
  // const [isPlayStart, setIsPlayStart] = useState(false)

  // --------------------- ===
  //  REFS
  // ---------------------
  const init = useRef(false)

  // --------------------- ===
  //  LOAD
  // ---------------------
  const load = async (zip: JSZip) => {
    const loader = Object.keys(zip.files).find((key) =>
      key.endsWith('loader.js')
    )
    const data = Object.keys(zip.files).find((key) => key.endsWith('data.gz'))
    const framework = Object.keys(zip.files).find((key) =>
      // key.endsWith('framework.js.gz') // did not match on the Cap&co game
      key.endsWith('js.gz')
    )
    const code = Object.keys(zip.files).find((key) => key.endsWith('wasm.gz'))

    if (!loader || !data || !framework || !code) {
      console.warn('Files not found', loader, data, framework, code)
      return
    }

    const loaderUrl = await createObjectURLFromArchive(zip, loader)
    const dataUrl = await createObjectURLFromArchive(zip, data)
    const frameworkUrl = await createObjectURLFromArchive(zip, framework)
    const codeUrl = await createObjectURLFromArchive(zip, code)

    setFiles({
      loaderUrl,
      dataUrl,
      frameworkUrl,
      codeUrl,
    })
  }

  // --------------------- ===
  //  EFFECTS
  // ---------------------

  useEffect(() => {
    if (init.current || !gameBuild) return // stops double call in dev strict mode
    console.log('----------------loading game')
    init.current = true
    window.fetch(gameBuild.build).then((res) => {
      res.blob().then((blob) => {
        JSZip.loadAsync(blob).then((zip) => {
          load(zip)
        })
      })
    })
  }),
    []

  // --------------------- ===
  //  RENDER
  // ---------------------
  // THIS WORKS :)
  const unityConfig: UnityConfig = {
    loaderUrl: files.loaderUrl,
    dataUrl: files.dataUrl,
    frameworkUrl: files.frameworkUrl,
    codeUrl: files.codeUrl,
  }

  // Note: useUnityContext in Canvas can't handle nullish values
  // thus we check for unityConfig.loaderUrl before rendering the component
  // and then send all the loading info back here
  return (
    <div className="w-full h-full">
      <GameSDKMonitor userJWT={props.jwt} />
      {unityConfig.loaderUrl && (
        <div
          className="w-full h-full"
          // onPointerDown={() => {
          //   setIsPlayStart(true)
          // }}
          // onClick={() => {
          //   setIsPlayStart(true)
          // }}
        >
          <Canvas
            unityConfig={unityConfig}
            setIsLoaded={(_isLoaded) => {
              setIsLoaded(_isLoaded)
            }}
            setLoadingProgression={(pct) => {
              setLoadingProgression(Math.round(pct * 100))
            }}
            setError={(err) => {
              setErrorMsg(err.message)
            }}
          />
        </div>
      )}
      {!isLoaded && (
        <CapncoLoader
          errorMsg={errorMsg}
          loadingProgression={loadingProgression}
        />
      )}
    </div>
  )
}
