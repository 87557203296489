import JSZip from 'jszip'
import Pako from 'pako'

export const createObjectURLFromArchive = async (
  archive: JSZip,
  path: string
) => {
  const zipFile = archive.file(path)

  // if (!zipFile || !zipFile.dir) {
  if (!zipFile) {
    throw new Error(`File not found: ${path}`)
  }

  if (zipFile.name.endsWith('.gz')) {
    const content = await zipFile.async('uint8array')
    const uncompressed = Pako.inflate(content)
    const blob = new Blob([uncompressed])
    return URL.createObjectURL(blob)
  } else {
    const blob = await zipFile.async('blob')
    return URL.createObjectURL(blob)
  }
}
