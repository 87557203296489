import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { UnityGame } from './UnityGame'
import { useGetPlayableGame } from './useGetPlayableGame'
import { GameBuild } from './types'
import { AES, enc } from 'crypto-js'

export const PlayPage = () => {
  const container = useRef<HTMLDivElement>(null)

  const [searchParams, setSearchParams] = useSearchParams()

  const [isFullScreen, setIsFullScreen] = useState(
    searchParams.get('fullscreen') === 'true'
  )

  const [actualToken, setActualToken] = useState(
    localStorage.getItem('odyn_jwt')
  )
  const encryptedToken = searchParams.get('t')

  const { activeGame } = useGetPlayableGame()

  useEffect(() => {
    const checkFullScreen = () => {
      setIsFullScreen(!!document.fullscreenElement)
    }
    // iOS devices don't want to fullscreen on page load
    if (!container.current || !container.current.requestFullscreen) {
      setIsFullScreen(false)
      return
    }
    if (isFullScreen) {
      window.addEventListener('fullscreenchange', checkFullScreen)
      container.current?.requestFullscreen().catch(() => {
        setIsFullScreen(false)
      })
    } else {
      if (document.fullscreenElement) document.exitFullscreen()
    }
    return () => {
      window.removeEventListener('fullscreenchange', checkFullScreen)
    }
  }, [isFullScreen])

  useEffect(() => {
    if (encryptedToken) {
      console.log(encryptedToken)

      const bytes = AES.decrypt(
        decodeURIComponent(encryptedToken),
        import.meta.env.VITE_ODYN_TOKEN_KEY
      )
      const token = bytes.toString(enc.Utf8)
      localStorage.setItem('odyn_jwt', token)
      setActualToken(token)

      // Remove token from  url
      searchParams.delete('t')
      setSearchParams(searchParams)
    }
  }, [encryptedToken])

  return (
    <div ref={container} className="w-screen h-screen bg-black">
      <UnityGame gameBuild={activeGame as GameBuild} jwt={actualToken || ''} />
    </div>
  )
}
