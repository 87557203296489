import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CapncoLogo,
  CloseIcon,
  DiscordIcon,
  MenuIcon,
  TwitterIcon,
} from '../assets/Icons'
import { SolidButton } from './components'
import {
  accountUrl,
  blogUrl,
  discordUrl,
  docsUrl,
  twitterUrl,
  wikiUrl,
} from './links'
import gsap from 'gsap'

const PlayButton = () => {
  const [isHover, setIsHover] = useState(false)

  const navigate = useNavigate()

  return (
    <svg
      width="179"
      height="64"
      viewBox="0 0 179 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="169"
        height="54"
        rx="6"
        fill={isHover ? '#F27502' : '#FE902A'}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        // onClick={() => navigate('/play?fullscreen=true')}
        className="cursor-pointer"
      />
      <path
        className={`duration-300 ease-in-out pointer-events-none ${
          isHover ? '-translate-x-[15px] ' : ''
        }`}
        d="M43.72 31.38H43V35H39V20.6H43.58C47.4 20.6 50.12 22.24 50.12 25.94C50.12 29.62 47.54 31.38 43.72 31.38ZM43.92 24.7H43V27.88H43.92C45.24 27.88 46.02 27.38 46.02 26.28C46.02 25.18 45.22 24.7 43.92 24.7ZM51.4195 35V19.8H55.2195V35H51.4195ZM62.725 28.16C62.725 27.7 62.125 27.48 61.005 27.48C59.685 27.48 58.425 27.86 57.405 28.32L57.145 24.52C58.105 24.02 59.605 23.58 61.385 23.58C65.005 23.58 66.625 25.22 66.625 28.48V35H62.725V33.84C61.905 34.78 61.005 35.28 59.505 35.28C57.705 35.28 56.425 34.34 56.425 32.5C56.425 30.04 58.545 29.38 60.665 29.02C61.505 28.88 61.885 28.78 62.265 28.66C62.625 28.54 62.725 28.4 62.725 28.16ZM62.725 30.56C62.505 30.68 62.165 30.84 61.725 30.96C60.965 31.16 60.425 31.34 60.425 32.08C60.425 32.64 60.865 32.88 61.465 32.88C62.045 32.88 62.485 32.56 62.725 32.08V30.56ZM70.742 39.08C69.662 39.08 68.942 38.98 68.302 38.82L68.562 35.74C68.982 35.84 69.282 35.92 69.862 35.92C70.782 35.92 71.282 35.74 71.402 35.3L71.442 35.16L67.102 23.86H71.662L73.542 31.62L75.382 23.86H79.642L75.582 35C74.802 37.12 73.562 39.08 70.742 39.08ZM84.918 35V20.6H88.918L94.078 28.9V20.6H97.778V35H93.778L88.618 27.4V35H84.918ZM99.1813 29.44C99.1813 26.06 101.641 23.58 105.141 23.58C108.641 23.58 111.081 26.06 111.081 29.44C111.081 32.82 108.641 35.28 105.141 35.28C101.641 35.28 99.1813 32.82 99.1813 29.44ZM105.141 31.78C106.201 31.78 107.081 30.98 107.081 29.66C107.081 28.42 106.261 27.48 105.141 27.48C104.001 27.48 103.181 28.42 103.181 29.66C103.181 30.98 104.061 31.78 105.141 31.78ZM122.581 35L120.681 28.34L118.941 35H114.441L111.321 23.86H115.721L117.181 31.02L118.801 23.86H122.901L124.621 31.06L125.961 23.86H130.161L127.081 35H122.581Z"
        fill="white"
      />

      <path
        className={`duration-300 ease-in-out pointer-events-none ${
          isHover ? 'opacity-100' : 'opacity-0'
        }`}
        d="M142.356 24.7313L131.625 18.5313C131.318 18.3498 130.969 18.2527 130.613 18.25C130.052 18.25 129.515 18.4726 129.119 18.8687C128.723 19.2649 128.5 19.8022 128.5 20.3625V33.6688C128.5 34.0406 128.6 34.4055 128.789 34.7258C128.978 35.046 129.249 35.3098 129.574 35.4897C129.9 35.6696 130.267 35.7591 130.639 35.7488C131.01 35.7385 131.373 35.6289 131.688 35.4313L142.431 28.6438C142.765 28.4347 143.04 28.1426 143.227 27.7961C143.415 27.4495 143.509 27.0603 143.502 26.6663C143.494 26.2722 143.385 25.8869 143.184 25.5478C142.983 25.2087 142.698 24.9274 142.356 24.7313Z"
        fill="white"
      />

      <mask id="path-2-inside-1_3836_1293" fill="white">
        <path d="M10 16C10 12.6863 12.6863 10 16 10H179V48C179 56.8366 171.837 64 163 64H10V16Z" />
      </mask>
      <path
        className={`duration-300 ease-in-out pointer-events-none ${
          isHover ? 'opacity-100' : '-translate-x-2 -translate-y-2 opacity-0'
        }`}
        d="M10 10H179H10ZM181 48C181 57.9411 172.941 66 163 66H10V62H163C170.732 62 177 55.732 177 48H181ZM10 64V10V64ZM181 10V48C181 57.9411 172.941 66 163 66V62C170.732 62 177 55.732 177 48V10H181Z"
        fill="#F27502"
        mask="url(#path-2-inside-1_3836_1293)"
      />
    </svg>
  )
}

export const Header: FC<{ isFormPage?: boolean }> = ({ isFormPage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()

  const openLink = (url: string) => {
    window.open(url, '_blank')
  }

  const handlePlayClick = () => {
    const playSection = document.querySelector('#playSection')

    if (playSection) {
      // Calculation of offset, so after scroll the play section will be at the very bottom of the viewport
      const bbox = playSection.getBoundingClientRect()
      const sectionHeight = bbox.bottom - bbox.top
      const headerHeight = 100

      const offset = (window.innerHeight - sectionHeight - headerHeight) / 2

      gsap.to('#scroll-container', {
        duration: 1,
        scrollTo: { y: playSection, offsetY: offset },
        ease: 'power2.out',
      })
    }
  }

  return (
    <div className="py-4 px-[30px] flex items-center gap-12 max-lg:gap-6">
      <div className="cursor-pointer" onClick={() => navigate('/')}>
        <CapncoLogo />
      </div>
      <div className="max-lg:hidden px-4 flex gap-6 text-lg font-medium">
        <div
          className="px-4 py-[6px] cursor-pointer"
          onClick={() => openLink(accountUrl)}
        >
          Account
        </div>
        <div
          className="px-4 py-[6px] cursor-pointer"
          onClick={() => openLink(blogUrl)}
        >
          Blog
        </div>
        <div
          className="px-4 py-[6px] cursor-pointer"
          onClick={() => openLink(wikiUrl)}
        >
          Wiki
        </div>
        <div
          className="px-4 py-[6px] cursor-pointer"
          onClick={() => openLink(docsUrl)}
        >
          Docs
        </div>
      </div>
      <div className="grow"></div>
      <div className="max-sm:hidden flex gap-6">
        <div
          className="p-3 bg-white bg-opacity-5 rounded-lg cursor-pointer"
          onClick={() => openLink(twitterUrl)}
        >
          <TwitterIcon />
        </div>
        <div
          className="p-3 bg-white bg-opacity-5 rounded-lg cursor-pointer"
          onClick={() => openLink(discordUrl)}
        >
          <DiscordIcon />
        </div>
      </div>
      {!isFormPage && (
        <div
          onClick={handlePlayClick}
          className="max-sm:hidden relative h-[54px] w-[169px]"
        >
          <PlayButton />
        </div>
      )}

      {!isFormPage && (
        <div
          className="hidden max-lg:block p-3 bg-white bg-opacity-5 rounded-lg cursor-pointer"
          onClick={() => setIsMenuOpen(true)}
        >
          <MenuIcon />
        </div>
      )}

      {isMenuOpen && (
        <div className="z-50 fixed inset-0 size-full bg-[#0A101B]">
          <div className="h-full flex flex-col">
            <div className="py-4 px-[30px] flex justify-between items-center">
              <CapncoLogo />
              <div
                className=" p-3 bg-white bg-opacity-5 rounded-lg cursor-pointer"
                onClick={() => setIsMenuOpen(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="grow p-6 flex flex-col gap-6 items-center text-2xl font-medium">
              <div
                className="px-4 py-[6px] cursor-pointer"
                onClick={() => openLink(accountUrl)}
              >
                Account
              </div>
              <div
                className="px-4 py-[6px] cursor-pointer"
                onClick={() => openLink(blogUrl)}
              >
                Blog
              </div>
              <div
                className="px-4 py-[6px] cursor-pointer"
                onClick={() => openLink(wikiUrl)}
              >
                Wiki
              </div>
              <div
                className="px-4 py-[6px] cursor-pointer"
                onClick={() => openLink(docsUrl)}
              >
                Docs
              </div>
              <SolidButton
                className="w-full"
                onClick={() => {
                  setIsMenuOpen(false)
                  handlePlayClick()
                }}
              >
                Play Now
              </SolidButton>
              <div className="grow"></div>
              <div className="flex gap-6">
                <div
                  className="p-3 bg-white bg-opacity-5 rounded-lg cursor-pointer"
                  onClick={() => openLink(twitterUrl)}
                >
                  <TwitterIcon />
                </div>
                <div
                  className="p-3 bg-white bg-opacity-5 rounded-lg cursor-pointer"
                  onClick={() => openLink(discordUrl)}
                >
                  <DiscordIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
