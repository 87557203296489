import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { App } from './App.tsx'
import './index.css'
import { PlayPage } from './pages/PlayPage/PlayPage.tsx'
import { FormPage } from './pages/Form/FormPage.tsx'

const router = createBrowserRouter([
  {
    path: '/play',
    element: <PlayPage />,
  },
  {
    path: '/form',
    element: <FormPage />,
  },
  {
    path: '*',
    element: <App />,
  },
])

const Main = () => {
  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  )
}

createRoot(document.getElementById('root')!).render(<Main />)
