import axios from 'axios'
import { useEffect, useState } from 'react'
import { GameBuild, WithCount } from './types'

const getWebGLBuild = (gameBuilds?: GameBuild[]) => {
  const builds = gameBuilds?.filter((x) => x.platform === 'browser')
  if (!builds?.length) return null
  let build = builds[0]
  builds.forEach((x) => {
    if (x.id > build.id) build = x
  })
  return build
}

export const useGetPlayableGame = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [activeGame, setActiveGame] = useState<GameBuild | null>(null)

  useEffect(() => {
    const fetchGameBuild = async () => {
      setIsLoading(true)
      try {
        const gameBuilds = await axios
          .get<WithCount<GameBuild[]>>(
            import.meta.env.VITE_GAME_BUILD_ENDPOINT_URL
          )
          .then((r) => r.data)

        const webgl = getWebGLBuild(gameBuilds.results)
        setActiveGame(webgl)
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    }

    fetchGameBuild()
  }, [])

  return {
    isLoading,
    activeGame,
  }
}
