import { useEffect, useState } from 'react'
import { CloseIcon, PlatformIcons } from '../../../assets/Icons'
import { useNavigate } from 'react-router-dom'
import IOSInstruction1URL from '../../../assets/IOSInstruction1.png'
import IOSInstruction2URL from '../../../assets/IOSInstruction2.png'
import IOSInstruction2_2URL from '../../../assets/IOSInstruction2_2.png'
import IOSInstruction3URL from '../../../assets/IOSInstruction3.png'

const ComingSoonBanner = () => {
  return (
    <div className="absolute top-0 -translate-y-1/2 right-2 w-fit">
      <div className="px-1 py-[2px] text-white text-[12px] font-bold uppercase bg-[#FE902A] rounded-[2px]">
        Coming Soon
      </div>
    </div>
  )
}

let defferPromptEvent: any = null

export const PlaySection = () => {
  const [isIOSModalOpen, setIsIOSModalOpen] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      defferPromptEvent = e
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      )
    }
  }, [])

  const handlePWAClick = () => {
    if (['iPhone', 'iPad'].includes(navigator.platform)) {
      setIsIOSModalOpen(true)
      return
    }

    if (defferPromptEvent && defferPromptEvent.prompt) {
      defferPromptEvent.prompt()
      defferPromptEvent.userChoice.then((choiceResult: any) => {
        if (choiceResult?.outcome === 'accepted') {
          console.log('User chose to install the app')
          navigate('/play')
        } else {
          console.log('User dismissed the install prompt')
        }
      })
    }
  }

  return (
    <div id="playSection" className="w-full ">
      <div className="flex max-lg:flex-col max-lg:items-center justify-center gap-[60px] max-lg:gap-10">
        <div className="flex flex-col text-white -rotate-2 max-lg:rotate-0 max-lg:text-center">
          <div className="text-5xl font-bold">Play Now!</div>
          <div className="text-xl font-medium">On any platform</div>
        </div>

        <div className="flex max-lg:flex-col items-center gap-5">
          <div className="flex max-sm:flex-col max-sm:items-center gap-5">
            <div className="relative cursor-pointer" onClick={handlePWAClick}>
              <PlatformIcons.PWA />
            </div>
            <div className="flex gap-5">
              <div className="relative">
                <PlatformIcons.GooglePlay />
                <ComingSoonBanner />
              </div>
              <div className="relative">
                <PlatformIcons.AppStore />
                <ComingSoonBanner />
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <div className="relative">
              <PlatformIcons.Steam />
              <ComingSoonBanner />
            </div>
            <div className="relative">
              <PlatformIcons.Epic />
              <ComingSoonBanner />
            </div>
          </div>
        </div>
      </div>

      {isIOSModalOpen && (
        <div className="z-50 fixed inset-0 size-full bg-[#0A101B]/90 backdrop-blur-lg">
          <div className="h-full flex flex-col">
            <div className="py-6 px-[30px] flex justify-between items-center">
              <div className="grow"></div>
              <div
                className="cursor-pointer"
                onClick={() => setIsIOSModalOpen(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="grow p-6 flex flex-col gap-9 items-center text-white overflow-y-scroll [&::-webkit-scrollbar]:hidden">
              <div className="text-xl font-medium">
                How to install C&C on Your iPhone
              </div>
              <div className="flex flex-col gap-6 items-center">
                <div>Click on middle icon</div>
                <div className="relative w-[298px] h-[102px]">
                  <img src={IOSInstruction1URL} className="rounded-lg" alt="" />
                  <div className="absolute -inset-[15px]">
                    <div className="size-[30px] flex items-center justify-center bg-[#FE902A] border-2 border-white rounded-full font-bold">
                      1
                    </div>
                  </div>
                  <div className="absolute size-[93px] top-[32px] left-1/2 -translate-x-1/2 rounded-full border border-white/20 overflow-hidden bg-black">
                    <img
                      src={IOSInstruction1URL}
                      className="w-[401.65px] h-[137.48px] object-cover -translate-y-[37px]"
                      alt=""
                    />
                  </div>
                </div>
                <div>Find the Add to home screen option</div>
                <div className="relative w-[298px] h-[120px]">
                  <img
                    src={IOSInstruction2URL}
                    className="rounded-lg size-full"
                    alt=""
                  />
                  <div className="absolute -inset-[15px]">
                    <div className="size-[30px] flex items-center justify-center bg-[#FE902A] border-2 border-white rounded-full font-bold">
                      2
                    </div>
                  </div>
                  <div className="absolute size-[150px] top-[6px] left-[190px] rounded-full 0border 0border-white/20 0bg-black">
                    <img
                      src={IOSInstruction2_2URL}
                      className="size-full"
                      alt=""
                    />
                  </div>
                </div>
                <div>Enjoy the game from your home screen</div>
                <div className="relative w-[298px] h-[102px]">
                  <img src={IOSInstruction3URL} className="rounded-lg" alt="" />
                  <div className="absolute -inset-[15px]">
                    <div className="size-[30px] flex items-center justify-center bg-[#FE902A] border-2 border-white rounded-full font-bold">
                      3
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
