import logo from './logo-text.png'
import wheel from './logo-wheel.png'

interface Props {
  errorMsg: string
  loadingProgression: number
}

export const CapncoLoader = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { errorMsg, loadingProgression } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="absolute inset-0 flex flex-col gap-8 items-center justify-center bg-white">
      <div className="w-[35rem] max-w-[90%] flex gap-4 items-center justify-center">
        <div className="basis-[35%] flex-1">
          <img
            src={wheel}
            className="animate-spin"
            style={{
              animationDuration: '20s',
            }}
            alt=""
          />
        </div>
        <div className="basis-[65%] flex-1">
          <img src={logo} className="" alt="Captain and Company loading" />
        </div>
      </div>
      <div className="pl-16">
        <div className="relative w-96 h-1.5 bg-neutral-200">
          <div
            className="absolute inset-0 bg-[#dbbd7a] transition-all"
            style={{
              right: `${100 - loadingProgression}%`,
            }}
          />
          {errorMsg && <p className="text-theme-error">{errorMsg}</p>}
          <p className="sr-only">{`Loading ${loadingProgression}`}</p>
        </div>
      </div>
    </div>
  )
}
