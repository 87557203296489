import { useEffect, useState } from 'react'
import { useBlocker } from 'react-router-dom'
import type { UnityConfig } from 'react-unity-webgl'
import { Unity, useUnityContext } from 'react-unity-webgl'

interface Props {
  unityConfig: UnityConfig
  setIsLoaded: (isLoaded: boolean) => void
  setLoadingProgression: (pct: number) => void
  setError: (err: Error) => void
}

export const Canvas = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { unityConfig, setIsLoaded, setLoadingProgression, setError } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const {
    unityProvider,
    unload,
    isLoaded,
    loadingProgression,
    initialisationError: error,
  } = useUnityContext(unityConfig)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isLoaded && nextLocation?.pathname !== currentLocation?.pathname
  )

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    // Note that unloading might have issues
    // https://react-unity-webgl.dev/docs/api/unload
    // https://github.com/jeffreylanters/react-unity-webgl/issues/22#issuecomment-1258119451
    if (blocker.state === 'blocked') {
      unload().then(() => {
        blocker.proceed()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state]) // only blocker.state

  useEffect(() => {
    // A function which will update the device pixel ratio of the Unity
    // Application to match the device pixel ratio of the browser.
    const updateDevicePixelRatio = () => {
      setDevicePixelRatio(window.devicePixelRatio)
    }
    // A media matcher which watches for changes in the device pixel ratio.
    const mediaMatcher = window.matchMedia(
      `screen and (resolution: ${devicePixelRatio}dppx)`
    )
    // Adding an event listener to the media matcher which will update the
    // device pixel ratio of the Unity Application when the device pixel
    // ratio changes.
    mediaMatcher.addEventListener('change', updateDevicePixelRatio)
    return () => {
      // Removing the event listener when the component unmounts.
      mediaMatcher.removeEventListener('change', updateDevicePixelRatio)
    }
  }, [devicePixelRatio])

  // LODING STATUSES
  useEffect(() => {
    setIsLoaded(isLoaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]) // only isLoaded

  useEffect(() => {
    setLoadingProgression(loadingProgression)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgression]) // only loadingProgression

  useEffect(() => {
    if (error) {
      setError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]) // only error

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="w-full h-full relative">
      <Unity
        unityProvider={unityProvider}
        style={{ width: '100%', height: '100%' }}
        devicePixelRatio={devicePixelRatio}
        tabIndex={0}
      />
    </div>
  )
}
