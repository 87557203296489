import axios from 'axios'
import gsap from 'gsap'
import { Fragment, useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import BackgroundImgURL from '../../assets/FormPageBackground.png'
import { Footer } from '../../common/Footer'
import { Header } from '../../common/Header'
import { horizontalLoop } from './helpers'

const CheckIcon = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M8.9242 19.2184C8.71419 19.4297 8.42768 19.5475 8.13005 19.5475C7.83243 19.5475 7.54591 19.4297 7.33591 19.2184L0.993641 12.8751C0.335453 12.2169 0.335453 11.1496 0.993641 10.4927L1.78779 9.69834C2.44618 9.04015 3.51224 9.04015 4.17043 9.69834L8.13005 13.6582L18.8295 2.95849C19.4879 2.3003 20.555 2.3003 21.2122 2.95849L22.0063 3.75284C22.6645 4.41103 22.6645 5.47811 22.0063 6.13527L8.9242 19.2184Z"
        fill="white"
      />
    </g>
  </svg>
)

const RunningLine = () => {
  useEffect(() => {
    const loop = horizontalLoop(gsap, '.running-line-text', {
      speed: 1,
      paddingRight: 25,
    })

    return () => loop.kill()
  }, [])

  return (
    <div
      style={{
        boxShadow: '0px 6px 0px 0px #00000040',
      }}
      className="w-[200%] py-[6px] max-md:py-[10px] px-6 flex items-center gap-5 max-md:gap-4 bg-[#EBE7FF] text-black border-y-2 border-black"
    >
      {Array(22)
        .fill(1)
        .map((_, i) => (
          <Fragment key={'rl' + i}>
            <div className="running-line-text text-2xl max-md:text-xl font-black">
              World First
            </div>
            <div className="running-line-text text-2xl max-md:text-xl font-black">
              •
            </div>
          </Fragment>
        ))}
    </div>
  )
}

export const FormPage = () => {
  const inputDivRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const buttonClaimRef = useRef<HTMLDivElement>(null)
  const buttonCheckRef = useRef<HTMLDivElement>(null)
  const buttonCheck2Ref = useRef<HTMLDivElement>(null)
  const successTextRef = useRef<HTMLDivElement>(null)

  const [email, setEmail] = useState('')
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isSubscribedError, setIsSubscribedError] = useState(false)

  useEffect(() => {
    setIsSubscribed(false)
    setIsSubscribedError(false)
    resetAnimation()
  }, [email])

  useEffect(() => {
    if (isSubscribed) {
      animateInput()
    }
  }, [isSubscribed])

  const submitEmail = async () => {
    if (isSubscribing) return

    setIsSubscribed(false)
    setIsSubscribedError(false)
    setIsSubscribing(true)
    try {
      await axios
        .post(import.meta.env.VITE_FORM_NEWSLETTER_ENDPOINT_URL, {
          email,
        })
        .then((r) => {
          console.log(r.data)
        })

      setIsSubscribed(true)
    } catch (error) {
      setIsSubscribedError(true)
    } finally {
      setIsSubscribing(false)
    }
  }

  // useEffect(() => {
  //   const ctx = gsap.context(() => {
  //     animateInput()
  //   })

  //   return () => ctx.revert()
  // }, [])

  const animateInput = () => {
    const tl = gsap.timeline()

    tl.to(inputDivRef.current, {
      width: '+=16',
      height: '+=20',
      translateX: '-=8',
      translateY: '-=10',
      duration: 1,
      onComplete: () => {
        if (buttonClaimRef.current && buttonCheckRef.current) {
          buttonClaimRef.current.classList.add('hidden')
          buttonCheckRef.current.classList.remove('hidden')
        }
      },
    })

    tl.to(buttonRef.current, {
      width: 127,
      background: '#70D660',
      duration: 0.8,
      ease: 'elastic.out',
    })

    tl.to(
      successTextRef.current,
      {
        marginTop: 8,
        opacity: 1,
        duration: 0.8,
        ease: 'elastic.out',
      },
      '<'
    )

    tl.to(
      inputDivRef.current,
      {
        width: '-=16',
        height: '-=20',
        translateX: '+=8',
        translateY: '+=10',
        duration: 0.8,
        boxShadow: '0px 0px 20px 0px #70D660',
        ease: 'elastic.out',
      },
      '<'
    )

    tl.to(buttonCheck2Ref.current, {
      scrollTo: 39,
      duration: 0.5,
      delay: 0.5,
      ease: 'back.out',
    })
  }

  const resetAnimation = () => {
    if (
      buttonClaimRef.current &&
      buttonCheckRef.current &&
      inputDivRef.current &&
      buttonRef.current &&
      successTextRef.current &&
      buttonCheck2Ref.current
    ) {
      buttonClaimRef.current.classList.remove('hidden')
      buttonCheckRef.current.classList.add('hidden')
      inputDivRef.current.style.boxShadow = ''
      buttonRef.current.style.width = ''
      buttonRef.current.style.background = ''
      successTextRef.current.style.marginTop = ''
      successTextRef.current.style.opacity = ''
      buttonCheck2Ref.current.scrollTo({ top: 0 })
    }
  }

  return (
    <div className="w-screen h-screen flex flex-col bg-[#0A101B] text-[#A4ABB6] overflow-hidden">
      <div className="fixed h-fit inset-0 z-20 bg-[#0A101B]/70 backdrop-blur-lg">
        <Header isFormPage />
      </div>
      <div className="w-full relative overflow-y-scroll [&::-webkit-scrollbar]:hidden overflow-x-hidden">
        <div className="relative z-10 pl-[262px] max-lg:pl-[50px] py-[270px] max-md:px-[80px] max-md:pt-[370px] max-md:pb-[33px] max-sm:px-6">
          <div className="w-[780px] max-md:w-full text-white font-bold text-8xl max-md:text-5xl uppercase drop-shadow-lg">
            Fair Launch Node Sale
          </div>
          <div className="mt-4 max-md:mt-3 w-[600px] max-md:w-full text-white text-2xl max-md:text-lg drop-shadow-2xl0">
            Claim a Platinum WL position to participate at the earliest $2mn
            FDV!
          </div>
          <div
            ref={inputDivRef}
            className="mt-9 w-[607px] max-md:w-full p-1 flex gap-2 bg-white rounded-lg shadow-s0 shadow-[#70D660]0"
          >
            <input
              className="grow min-w-0 pl-4 text-xl outline-none focus:ring-0 border-transparent focus:border-transparent text-black"
              placeholder="Yer Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submitEmail()
                }
              }}
            />
            <button
              ref={buttonRef}
              onClick={submitEmail}
              disabled={isSubscribing}
              className="py-[13px] px-6 bg-[#FE902A] rounded-md cursor-pointer text-white  text-xl font-black whitespace-nowrap hover:bg-[#F27502] disabled:bg-[#FE902A]/70"
            >
              <div ref={buttonClaimRef}>Claim</div>
              <div
                ref={buttonCheckRef}
                className="hidden w-full flex justify-center"
              >
                <div
                  ref={buttonCheck2Ref}
                  className="size-[22px] flex flex-col gap-[17px] pb-1 overflow-y-scroll [&::-webkit-scrollbar]:hidden"
                >
                  <div className="size-[22px]">
                    <CheckIcon />
                  </div>
                  <div className="size-[22px]">
                    <CheckIcon />
                  </div>
                </div>
              </div>
            </button>
          </div>
          <div
            ref={successTextRef}
            className="pointer-events-none opacity-0 -mt-8 ml-4 text-[#70D660] font-medium text-[15px]"
          >
            Yer in! Email invite coming soon.
          </div>
          {isSubscribedError && (
            <div className="mt-2 ml-4 text-[#ed4f4f]">
              Something went wrong, please try again.
            </div>
          )}
        </div>
        <Footer />
        <div className="pointer-events-none absolute -top-[200px] -right-[200px] 0left-[660px] w-[1475px] h-[1279px] max-md:w-[833px] max-md:h-auto max-md:-top-[160px] max-md:left-[50%] max-md:-translate-x-1/2 max-sm:w-[565px] max-sm:-top-[30px]">
          <img className="size-full" src={BackgroundImgURL} alt="" />
        </div>
        <div className="z-20 absolute left-0 top-[150px] max-lg:top-[90px] max-md:top-[250px] -rotate-[4deg]">
          <RunningLine />
        </div>

        {isSubscribed && <Confetti numberOfPieces={100} gravity={0.04} />}
      </div>
    </div>
  )
}
