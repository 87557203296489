import gsap from 'gsap'
import { Header } from './common/Header'
import { Home } from './pages/Home/Home'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
// import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollToPlugin)

export const App = () => {
  const [installPrompt, setInstallPrompt] = useState<(() => void) | null>(null)
  // const location = useLocation()

  // useEffect(() => {
  //   if (location.pathname === '/play') {
  //     if ('serviceWorker' in navigator) {
  //       navigator.serviceWorker
  //         .register('/sw.js')
  //         .then(() => {
  //           console.log('Service Worker registered for /play route')
  //         })
  //         .catch((error) => {
  //           console.error('Service Worker registration failed:', error)
  //         })
  //     }
  //   }
  // }, [location.pathname])

  const navigate = useNavigate()

  // useEffect(() => {
  //   const isPWAMode = window.matchMedia('(display-mode: standalone)').matches

  //   if (isPWAMode) {
  //     console.log('The app is installed as a PWA.')
  //     navigate('/play')
  //   } else {
  //     console.log('The app is not installed.')
  //   }
  // }, [])

  return (
    <div className="w-screen h-screen flex flex-col bg-[#0A101B] text-[#A4ABB6] overflow-hidden">
      <Header />
      <Home />
    </div>
  )
}
