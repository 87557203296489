export const twitterUrl = 'https://x.com/capncompany'
export const twitchUrl = 'https://www.twitch.tv/capncompany'
export const discordUrl = 'https://discord.com/invite/capncompany'
export const youtubeUrl =
  'https://www.youtube.com/channel/UCahuZZcE8WwSA4aCR_4A_ug'
export const playUrl = 'https://www.kap.gg/game/capncompany'
export const accountUrl = 'https://kap.gg/profile'
export const blogUrl = 'https://blog.capnco.gg/'
export const docsUrl = 'https://docs.capnco.gg/'
export const wikiUrl = 'http://wiki.capnco.gg/'
