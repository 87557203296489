import { useEffect } from 'react'
// import { useSelector } from 'react-redux'

// import { modal, useOdynDispatch } from '@odyn/redux'
// import type { OdynRootState } from '@odyn/redux'
// import type { User } from '@odyn/redux'

// type UserInfo = {
//   token: string
//   user: User
// }

export const GameSDKMonitor = ({ userJWT }: { userJWT: string }) => {
  // --------------------- ===
  //  STORE
  // ---------------------
  // const dispatch = useOdynDispatch()
  // const { showModal } = modal.actions
  // const user = useSelector((store: OdynRootState) => store.auth.currentUser)
  // const navigate = useNavigate()
  // const location = useLocation()
  // console.log(userJWT)

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const postMessageToGame = (method: string, params?: any) => {
    const data = {
      data: {
        method,
        params,
      },
    }
    window.postMessage(data, '*')
  }
  // sends users jwt to the iframe in a postMessage to be picked up by the game sdk
  const sendJWT = async (token: string | null) => {
    // console.log('sdk', token)

    if (!token) {
      console.error('user token not set')
    }
    postMessageToGame('provide_token', token)
  }

  // show login modal and trigger callbacks once modal is closed
  const triggerLogin = () => {
    // Slice the / from pathname as it will be added in Login.tsx
    window.open(import.meta.env.VITE_KAPGG_LOGIN_URL, '_self')
  }

  const handleMessage = (event: MessageEvent) => {
    const method = event.data.method
    switch (method) {
      case 'odyn-init':
        sendJWT(userJWT || null)
        break
      case 'odyn-login':
        triggerLogin()
        break
      default:
        return
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
    // having handleMessage in here creates a circular dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userJWT])

  return null
}
