import { Footer } from '../../common/Footer'
import { PlaySection } from './Sections/PlaySection'
import { Section1 } from './Sections/Section1'
import { Section2 } from './Sections/Section2'
import { Section3 } from './Sections/Section3'
import { Section4 } from './Sections/Section4'
import { Section5 } from './Sections/Section5'
import { Section6 } from './Sections/Section6'

export const Home = () => {
  return (
    <div
      id="scroll-container"
      className="grow min-h-0 flex flex-col items-center overflow-x-hidden overflow-y-scroll [&::-webkit-scrollbar]:hidden scroll-smooth"
    >
      <Section1 />
      <PlaySection />
      <div className="max-w-[1920px] px-20 max-lg:px-9 max-sm:px-0 pt-10 pb-20 flex flex-col gap-20 max-lg:gap-0">
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
      </div>
      <Footer />
    </div>
  )
}
