import gsap from 'gsap'
import { FC, useEffect, useRef, useState } from 'react'
import { HomepageBottomPattern, RightArrowIcon } from '../../../assets/Icons'
import HeroVideoURL from '../../../assets/videos/Hero.mp4'
import VideoHeroPosterURL from '../../../assets/VideoHeroPoster.webp'
import { useGSAP } from '@gsap/react'
import axios from 'axios'

const Button: FC<{
  onClick(): void
  disabled?: boolean
}> = (props) => {
  const [isHover, setIsHover] = useState(false)

  const handleClick = () => {
    if (props.disabled) return

    setIsHover(false)

    props.onClick()
  }

  return (
    <div
      style={{
        cursor: !props.disabled ? 'pointer' : undefined,
        background: props.disabled ? '#FE902AB9' : undefined,
      }}
      className="overflow-hidden relative w-[213px] min-w-[213px] h-[60px] bg-[#FE902A] rounded-md text-white  text-xl font-black whitespace-nowrap"
      onMouseEnter={() => setIsHover(true && !props.disabled)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleClick}
    >
      <div
        className={`z-10 duration-500 ease-in-out absolute bottom-0 left-1/2 -translate-x-1/2  rounded-full bg-[#F27502]  ${
          isHover
            ? 'size-[280px]  translate-y-[140px]'
            : 'size-10 translate-y-full'
        }`}
      ></div>
      <div className="z-20 absolute inset-0 size-full flex justify-center items-center ">
        <div
          className={`duration-300 ease-in-out cursor-default pointer-events-none ${
            isHover ? 'mr-[10px]' : ''
          }`}
        >
          Join the Crew!
        </div>
        <div className={`${isHover ? 'w-fit opacity-100' : 'w-0 opacity-0'}`}>
          <RightArrowIcon />
        </div>
      </div>
    </div>
  )
}

const MouseIcon = () => {
  const svgRef = useRef<SVGSVGElement>(null)
  const scrollRef = useRef<SVGRectElement>(null)

  useGSAP(() => {
    gsap.to(svgRef.current, {
      delay: 2.5,
      opacity: 1,
      duration: 0.3,
      onComplete: () => {
        const tl = gsap.timeline({
          repeat: -1,
        })

        tl.to(scrollRef.current, {
          y: 10,
          duration: 0.5,
          delay: 0.8,
        })

        tl.to(scrollRef.current, {
          y: 0,
          duration: 0.5,
          delay: 0.8,
        })
      },
    })
  })

  return (
    <svg
      width="103"
      height="103"
      viewBox="0 0 103 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      className="opacity-0"
    >
      <rect
        x="36"
        y="26"
        width="30"
        height="50"
        rx="15"
        stroke="#CCDAEA"
        strokeWidth="2"
      />
      <rect
        x="48"
        y="35"
        width="6"
        height="11"
        rx="3"
        fill="#CCDAEA"
        ref={scrollRef}
        className=""
      />
    </svg>
  )
}

export const Section1 = () => {
  const [email, setEmail] = useState('')
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isSubscribedError, setIsSubscribedError] = useState(false)

  const line1Ref = useRef<HTMLDivElement>(null)
  const line2Ref = useRef<HTMLDivElement>(null)
  const line3Ref = useRef<HTMLDivElement>(null)
  const line4Ref = useRef<HTMLDivElement>(null)

  const isLg = window.innerWidth <= 1280

  useEffect(() => {
    const ctx = gsap.context(() => {
      const duration = 0.65
      const tl = gsap.timeline()

      tl.to(line1Ref.current, {
        marginLeft: 150 - (isLg ? 120 : 0),
        ease: 'back.out',
        duration,
      })
      tl.to(line2Ref.current, {
        marginLeft: 230 - (isLg ? 160 : 0),
        ease: 'back.out',
        duration,
      })
      tl.to(line3Ref.current, {
        marginLeft: 300 - (isLg ? 200 : 0),
        ease: 'back.out',
        duration,
      })
      tl.to(
        line4Ref.current,
        {
          marginLeft: 270 - (isLg ? 220 : 0),
          ease: 'back.out',
          duration,
        },
        '<'
      )
    })

    // Clean up animations on unmount
    return () => ctx.revert()
  }, [])

  useEffect(() => {
    setIsSubscribed(false)
    setIsSubscribedError(false)
  }, [email])

  const submitEmail = async () => {
    if (isSubscribing) return

    setIsSubscribed(false)
    setIsSubscribedError(false)
    setIsSubscribing(true)
    try {
      await axios
        .post(import.meta.env.VITE_NEWSLETTER_ENDPOINT_URL, {
          email,
        })
        .then((r) => {
          console.log(r.data)
        })

      setIsSubscribed(true)
    } catch (error) {
      setIsSubscribedError(true)
    } finally {
      setIsSubscribing(false)
    }
  }

  return (
    <div className="relative min-h-[980px] overflow-hidden">
      <video
        className="w-full h-[980px] object-cover"
        src={HeroVideoURL}
        poster={VideoHeroPosterURL}
        autoPlay
        loop
        muted
      />
      <div className="absolute left-0 -bottom-1">
        <HomepageBottomPattern />
      </div>
      <div className="absolute inset-0 size-full bg-gradient-to-l from-[#0F1318]/0 to-[#0F1318]/80"></div>
      <div className="absolute left-1/2 -translate-x-1/2 bottom-14">
        <MouseIcon />
      </div>
      {/* Content */}
      <div className="max-sm:hidden absolute left-0 bottom-[320px]">
        <div className="-rotate-2 flex flex-col gap-2">
          <div
            ref={line1Ref}
            className="-ml-[100%] text-white text-8xl max-lg:text-[85px] font-bold"
          >
            REAL PIRACY
          </div>
          <div
            ref={line2Ref}
            className="-ml-[100%] text-white text-8xl max-lg:text-[85px] font-bold"
          >
            REAL REWARD
          </div>
          <div
            ref={line3Ref}
            className="-ml-[100%] text-white text-8xl max-lg:text-[85px] font-bold"
          >
            REAL RISK
          </div>
          <div
            ref={line4Ref}
            className="-ml-[110%] mt-10 w-[696px] p-1 flex gap-[29px] bg-white rounded-lg "
          >
            <input
              className="grow pl-4 text-xl outline-none focus:ring-0 border-transparent focus:border-transparent text-black"
              placeholder="Yer Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submitEmail()
                }
              }}
            />
            <Button onClick={submitEmail} disabled={isSubscribing} />
          </div>
          {isSubscribed && (
            <div
              style={{
                marginLeft: 290 - (isLg ? 220 : 0),
              }}
              className="text-white"
            >
              Welcome aboard, pirate!
            </div>
          )}
          {isSubscribedError && (
            <div
              style={{
                marginLeft: 290 - (isLg ? 220 : 0),
              }}
              className="text-[#ed4f4f]"
            >
              Something went wrong, please try again.
            </div>
          )}
        </div>
      </div>
      {/* Mobile Content */}
      <div className="hidden max-sm:block absolute size-full inset-0">
        <div className="px-6 pb-[100px] size-full flex flex-col justify-center">
          <div className="flex flex-col gap-2 text-6xl max-xsm:text-5xl text-white font-bold">
            <div>REAL PIRACY</div>
            <div>REAL REWARD</div>
            <div>REAL RISK</div>
          </div>
          <div className="w-full mt-10  p-1 flex gap-2 bg-white rounded-lg ">
            <input
              className="grow min-w-0 pl-4 text-xl outline-none focus:ring-0 border-transparent focus:border-transparent text-black"
              placeholder="Yer Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div
              className="px-4 w-fit h-[60px] bg-[#FE902A] rounded-md flex items-center text-white  text-xl font-black whitespace-nowrap"
              onClick={submitEmail}
            >
              <div>Join the Crew!</div>
            </div>
          </div>
          {isSubscribed && (
            <div className="ml-4 text-white">Welcome aboard, pirate!</div>
          )}
          {isSubscribedError && (
            <div className="ml-4 text-[#ed4f4f]">
              Something went wrong, please try again.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
