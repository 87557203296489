import { CSSProperties, FC, ReactElement } from 'react'

export const SolidButton: FC<{
  children: ReactElement | string | string[]
  onClick(): void
  disabled?: boolean
  style?: CSSProperties
  className?: string
}> = ({ children, onClick, disabled, style, className }) => {
  return (
    <button
      style={style ? { ...style } : {}}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      className={
        (className ? className + ' ' : '') +
        'py-[13px] px-[38px] bg-[#FE902A] rounded-md cursor-pointer text-white  text-xl font-black whitespace-nowrap hover:bg-[#F27502] disabled:bg-[#FE902A]/70'
      }
    >
      {children}
    </button>
  )
}
